import { useEffect } from 'react';
import { Box, HStack, Text, Tooltip, } from '@chakra-ui/react'
import { useStore } from '../../../store/store'
import React from 'react'

export const ConvoHeader = ({ isLoading, onOpen }) => {
    const widgetLinkColor = useStore(state => state.widgetSession.appearanceConfig?.linkColor)

    useEffect(() => {
        if (widgetLinkColor) {
            document.documentElement.style.setProperty('--widget-link-color', widgetLinkColor);
        }
    }, [widgetLinkColor]);

    const convos = useStore(state => state.convos)
    const setSendHistoryForm = useStore(state => state.setSendHistoryForm)
    const handleSendHistory = () => {
        setSendHistoryForm(true)
    }

    return <Box
        css={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '9px 0px',
            gap: '18px',
            position: 'sticky',
            top: 0,
            zIndex: 1,
            // margin: '0px 18px 9px 18px',
        }}
    >
        <HStack>
            <div className="h-12 leading-6 flex items-center text-white text-[16px] font-semibold">
                Conversation
            </div>

            {/* 
            <Tooltip
                label={
                    <>
                        <Text>Your conversation history is cleared after 24 hours of inactivity.</Text>
                    </>
                }
                hasArrow
                arrowSize={10}
                mt="10px"
                ml="-5px"
                aria-label="Tooltip for message autosave"
                backgroundColor="#FBF6FE"
                color="#000000"
                padding="18px 26px 18px 34px"
                maxW="351px"
                height="80px"
                placement="bottom-start"
                borderRadius="12px"
            >
                <Box
                    css={{
                        width: '14px',
                        height: '14px',
                        flexShrink: '0',
                        //borderRadius: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        transition: 'background-color 0.3s',
                        cursor: 'pointer',
                        background: 'rgba(255, 255, 255, 0.20)',
                        ':hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.40)',
                        },
                        borderRadius: '8px',
                    }}
                >
                    <Text
                        css={{
                            color: '#FFF',
                            textAlign: 'center',
                            fontFamily: 'Roboto Mono',
                            fontSize: '8px',
                            fontWeight: '700',
                        }}
                    >
                        i
                    </Text>
                </Box>
            </Tooltip>  */}

            {/* {!currentHistoryIndex !== 0 && showTimeTravelTT && (
            <Link
                css={{ fontSize: '13px', fontWeight: 600, lineHeight: '22px', }}
                color="link.normal"
                _hover={{ color: 'link.hover' }}
                _active={{ color: 'link.active' }}
                _disabled={{ color: 'link.disabled' }}
                onClick={() => scrollToTop(ref)}
            >
                scroll to top
            </Link>
        )} */}
        </HStack>

        <HStack spacing={4}>
            {convos.length > 1 && (
                <Text
                    onClick={handleSendHistory}
                    _hover={{ color: '#CCB4EB' }}
                    _active={{ color: '#7850A0' }}
                    mr="8px"
                    style={{
                        cursor: isLoading ? 'none' : 'pointer',
                        fontWeight: 600,
                        fontSize: '13px',
                        lineHeight: '18px',
                        color: widgetLinkColor,
                        opacity: isLoading ? 0.2 : 1,
                    }}
                >
                    share
                </Text>
            )}
            <Text
                id="cv-clear"
                onClick={isLoading ? null : onOpen}
                _hover={{ color: '#CCB4EB' }}
                _active={{ color: '#7850A0' }}
                style={{
                    cursor: isLoading ? 'none' : 'pointer',
                    fontWeight: 600,
                    fontSize: '13px',
                    lineHeight: '18px',
                    color: widgetLinkColor,
                    opacity: isLoading ? 0.2 : 1,
                }}
            >
                clear
            </Text>
        </HStack>
    </Box>
}