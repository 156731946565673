import axios from 'axios'
import { useApiConfig } from '../hooks/useApiConfig.js'
import { sentryUtils } from '../lib/index.js'
import { convertConvosToChronicle } from '../lib/utils.js'
import { widgetSettings } from '../settings/widget.settings.js'

export const fetchWebSearch = async ({
    targetRequestId,
    convos,
    prependConvos,
    widgetSession,
    widgetLog,
    updateConvo,
    setSearchResults,
    setEntLoading,
    setprevSrpRequestId,
    setSynthFilters
}) => {
    const { apiBaseUrl } = useApiConfig()
    const endpoint = `${apiBaseUrl}/web_search_results`

    try {
        updateConvo({ requestId: targetRequestId, isWebLoading: true })
        widgetLog('web_search')
        const { data } = await axios.post(endpoint, {
            user_id: widgetSession.id,
            product_url: widgetSession.url,
            product_name: widgetSession.productName,
            client_id: '',
            client_name: 'widget',
            user_input: 'search the web',
            message_history: convertConvosToChronicle(convos)
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (data.request_id) {
            prependConvos({ requestId: data.request_id, text: '' })
            listenForUpdates({
                requestId: data.request_id,
                targetRequestId,
                updateConvo,
                widgetSession,
                setSearchResults,
                setEntLoading,
                setprevSrpRequestId,
                setSynthFilters
            })
        } else {
            sentryUtils.captureApiError('No request id found', endpoint)
        }
    } catch (err) {
        sentryUtils.captureApiError(err, endpoint)
        return false
    }
    return true
}

function listenForUpdates({ requestId, targetRequestId,
    updateConvo,
    widgetSession,
    setSearchResults,
    setEntLoading,
    setprevSrpRequestId,
    setSynthFilters
}) {
    const { apiBaseUrl } = useApiConfig()
    const endpoint = `${apiBaseUrl}/web_search_response/${requestId}`

    const eventSource = new EventSource(endpoint)
    eventSource.onmessage = function (event) {
        if (event.data.includes('[STREAM DONE]')) {
            updateConvo({ requestId: targetRequestId, isWebLoading: false })
            eventSource.close()
        }
    }
    eventSource.addEventListener('answer', (event) => {
        updateConvo({ requestId: targetRequestId, isWebLoading: false })
        updateConvo({ requestId: requestId, text: event.data })
    })

    eventSource.addEventListener('search_results', (event) => {
        setEntLoading(true)
        try {
            const searchResults = JSON.parse(event.data)
            updateConvo({ requestId, searchResults })

            if (Array.isArray(searchResults)) { // Allow empty SR to clear up the previous one                
                setSearchResults(searchResults)
                setprevSrpRequestId(requestId)

                if (!widgetSession.isInstantSearchEnabled && searchResults.length > 0) {
                    window.parent.postMessage({
                        action: 'cv-iframe-resize',
                        width: chatPanelCurrentWidth + widgetSettings.SR_PANEL_DEFAULT_WIDTH,
                        rightPanelWidth: widgetSettings.SR_PANEL_DEFAULT_WIDTH
                    }, '*')
                }
            }
        } catch (err) {
            sentryUtils.captureApiError(err, endpoint)
        }
    })

    eventSource.addEventListener('filters', (event) => {
        setEntLoading(true)
        try {
            const data = JSON.parse(event.data)
            setSynthFilters(Object.entries(data))
            updateConvo({ requestId, synthFilters: Object.entries(data) })
        } catch (err) {
            sentryUtils.captureApiError(err, endpoint)
        }
    })

    eventSource.onerror = function (err) {
        sentryUtils.captureApiError(err, endpoint)
        eventSource.close()
    }
}


