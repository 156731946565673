import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { Search } from 'lucide-react'
export default function RelatedQuestions({ questions }) {
	return (
		<Box className="p-[18px] text-[#ffffffbf]">
			<div className="mb-3">You may be curious about...</div>
			<Flex wrap="wrap" maxW="332px" gap="18px" align="center" direction="column">
				{questions?.map((question) => (
					<div className="flex gap-[9px] justify-start items-center">
						<div className="w-[11px] h-[11.407px]">
							<Search size={11} color="#AA85D9" />
						</div>
						<p>{question.question_text}</p>
					</div>
				))}
			</Flex>
		</Box>
	)
}