import React from 'react';

export const ChatFooter = ({ domain }) => (
    <div className="flex justify-center items-center text-xs pb-4 text-[#AA85D9] pb-[18px] select-none w-full">
        {/* <a
            href={`https://www.conversant.ai/?utm_source=${domain}&utm_medium=enterprise`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex"
        > */}
        <img
            alt="Avatar System"
            src={'/icon/logo-footer-small.png'}
            className="h-[13px] w-[13px] mr-1"
        />
        Powered by Conversant.ai
        {/* </a> */}
    </div>
);